'use client'

import type { ReactNode } from 'react'
import Symbol from '../symbol'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid'

interface TooltipProps {
  children?: ReactNode
  content: string | ReactNode
  hasIcon?: boolean
}

const Tooltip: React.FC<TooltipProps> = ({ children, content, hasIcon }) => {
  return (
    <div className='tooltip w-fit break-words' data-tip={content}>
      {hasIcon ? <Symbol Icon={QuestionMarkCircleIcon} size='small' /> : children}
    </div>
  )
}

export default Tooltip
